import { all, fork } from "redux-saga/effects";
import leadSaga from "@containers/Lead/store/saga";
import sharedSagas from "@shared/store/saga";

import { saga as authSaga } from "../containers/Auth/store";

const allSagas = [authSaga, leadSaga, sharedSagas];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
