import React from "react";
import { Box } from "@chakra-ui/react";

import MainSidebar, { MainSidebarProps } from "./components/MainSidebar/MainSidebar";

const Sidebar = (props: MainSidebarProps) => {
  const { items, onLogoutClick, user } = props;

  return (
    <Box h="100%" w="72px" bg="brand.sidebarBackground" borderRight="1px solid #E9E9E9">
      <MainSidebar items={items} onLogoutClick={onLogoutClick} user={user} />
    </Box>
  );
};

export default Sidebar;
