import { AnyAction, combineReducers } from "redux";
import { AppState } from "@shared/interfaces";
import { LeadReducer } from "@containers/Lead/store/reducer";

import { AuthReducer } from "../containers/Auth/store/reducer";
import { SharedReducer } from "../shared/store/reducer";

export default () => {
  const appReducer = combineReducers({
    auth: AuthReducer,
    shared: SharedReducer,
    lead: LeadReducer,
  });

  return (state: AppState | undefined, action: AnyAction) => {
    return appReducer(state, action);
  };
};
