import React, { useCallback } from "react";
import classnames from "classnames";
import { FieldItemType } from "@shared/interfaces";
import { ErrorMessage, Input } from "@shared/components";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface TextFieldItem extends RenderField {
  type: FieldItemType.TEXT;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  helpText?: string;
  onChangeValue?: (value: string) => void;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  onChangeField?: (
    field: string,
    value: string,
    // eslint-disable-next-line
    values: any,
    setFieldValue?: (field: string, value: string) => void,
  ) => void;
}

export const GenerateTextField: GenerateRenderField<TextFieldItem> = (props) => {
  const {
    formikProps: { errors, touched, handleBlur, handleChange, values },
    wrapperClass,
    name,
    placeholder,
    disabled,
    type,
    label,
    onChangeField,
    onChangeValue,
    helpText,
  } = props;

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      handleChange(e);
      onChangeValue?.(e.currentTarget.value);
      onChangeField?.(name, e.currentTarget.value, values);
    },
    [onChangeField, handleChange, onChangeValue, values, name],
  );

  return (
    <div className={classnames(wrapperClass, { error: errors[name] && touched[name] })} id={name}>
      <Input
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={handleInputChange}
        onBlur={handleBlur}
        value={values[name]}
        helpText={helpText}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
