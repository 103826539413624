import { call, put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "@shared/interfaces";
import { startLoading, stopLoading } from "@shared/store/actions";
import { GetLead, SubmitMessage, Lead, Bubble, GetLeadsRequest, LeadScoreInterface } from "@containers/Lead/interface";
import { User } from "@shared/models";

import * as actions from "./actions";
import api from "../api";

function* loadLeadSaga({ payload }: ActionWithPayload<GetLead>): Generator {
  try {
    yield put(startLoading());
    const { chat: lead } = (yield call(api.loadLead, payload) as unknown) as { chat: Lead };
    yield put(actions.loadLead.success(lead));
  } catch (error) {
    yield put(actions.loadLead.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* loadLeadListSaga({ payload }: ActionWithPayload<GetLeadsRequest>): Generator {
  try {
    yield put(startLoading());
    const { chats: leads, total } = (yield call(api.loadLeadList, payload) as unknown) as {
      chats: Lead[];
      total: number;
    };
    yield put(actions.loadLeadList.success({ leads, total }));
  } catch (error) {
    yield put(actions.loadLeadList.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* submitMessageSaga({ payload }: ActionWithPayload<SubmitMessage>): Generator {
  try {
    yield put(startLoading());
    const { chat: lead } = (yield call(api.sendMessage, payload) as unknown) as { chat: Lead };
    yield put(actions.sendMessage.success(lead));
  } catch (error) {
    yield put(actions.sendMessage.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* startConversationSaga(): Generator {
  try {
    yield put(startLoading());
    const { bubbles } = (yield call(api.startConversation) as unknown) as { bubbles: Bubble[] };
    yield put(actions.startConversation.success(bubbles));
  } catch (error) {
    yield put(actions.startConversation.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* loadAgentsSaga(): Generator {
  try {
    yield put(startLoading());
    const { users } = (yield call(api.loadAgents) as unknown) as { users: User[] };
    yield put(actions.loadAgents.success(users));
  } catch (error) {
    yield put(actions.loadAgents.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* createScoreSaga({ payload }: ActionWithPayload<LeadScoreInterface>): Generator {
  try {
    yield put(startLoading());

    const { chat } = (yield call(api.createScore, payload) as unknown) as { chat: Lead };

    yield put(actions.createScore.success(chat));
  } catch (error) {
    yield put(actions.createScore.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateScoreSaga({ payload }: ActionWithPayload<LeadScoreInterface>): Generator {
  try {
    yield put(startLoading());

    const { chat } = (yield call(api.updateScore, payload) as unknown) as { chat: Lead };

    yield put(actions.createScore.success(chat));
  } catch (error) {
    yield put(actions.updateScore.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* dashboardSaga() {
  yield takeLatest(actions.loadLead.request, loadLeadSaga);
  yield takeLatest(actions.loadLeadList.request, loadLeadListSaga);
  yield takeLatest(actions.sendMessage.request, submitMessageSaga);
  yield takeLatest(actions.startConversation.request, startConversationSaga);
  yield takeLatest(actions.loadAgents.request, loadAgentsSaga);
  yield takeLatest(actions.createScore.request, createScoreSaga);
  yield takeLatest(actions.updateScore.request, updateScoreSaga);
}

export default dashboardSaga;
