import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from "@chakra-ui/react";
import { ReactNode, MouseEventHandler, FC } from "react";

export type ButtonStyle = "primary" | "accent" | "accent-green" | "bordered" | "draft" | "clear" | "cancel";

interface ButtonProps extends Omit<ChakraButtonProps, "variant"> {
  buttonStyle?: ButtonStyle;
  onClick?: MouseEventHandler;
  children: ReactNode;
}

const Button: FC<ButtonProps> = ({ buttonStyle, children, ...props }) => {
  let variant: string | undefined;

  switch (buttonStyle) {
    case "primary":
      variant = "primary";
      break;
    case "cancel":
      variant = "cancel";
      break;
    case "accent":
      variant = "solid";
      break;
    case "accent-green":
      variant = "outline";
      break;
    case "bordered":
      variant = "outline";
      break;
    case "draft":
      variant = "link";
      break;
    case "clear":
      variant = "ghost";
      break;
  }

  return (
    <ChakraButton variant={variant} {...props} colorScheme="brand">
      {children}
    </ChakraButton>
  );
};

export default Button;
