import { LoginShape } from "@containers/Auth/interface";
import { FieldItem } from "@shared/components/FormGenerator";
import { FORM_ERROR_MESSAGES, IS_VALID_EMAIL } from "@shared/constants";
import { FieldItemType } from "@shared/interfaces";
import * as Yup from "yup";

export const validationSchema: Yup.Schema<LoginShape> = Yup.object().shape({
  email: Yup.string()
    .matches(IS_VALID_EMAIL, FORM_ERROR_MESSAGES.EMAIL)
    .max(150, `Email ${FORM_ERROR_MESSAGES.LONG}`)
    .required(FORM_ERROR_MESSAGES.REQUIRED),
  password: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
});

export const fields: FieldItem[] = [
  {
    type: FieldItemType.TEXT,
    name: "email",
    placeholder: "Email",
    wrapperClass: "input-line",
  },
  {
    type: FieldItemType.PASSWORD,
    name: "password",
    placeholder: "Password",
    wrapperClass: "input-line",
    isShowPasswordIcon: true,
  },
];

export const initValues: LoginShape = {
  email: "",
  password: "",
};
