import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading } from "@shared/store/selectors";

import { LoginForm, AuthModal } from "../../components";
import { login } from "../../store/actions";
import { LoginShape } from "../../interface";

const LoginContainer: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading());

  const handleSubmit = (values: LoginShape) => {
    dispatch(login.request({ ...values }));
  };

  return (
    <AuthModal title="Log  in to Your Account">
      <LoginForm loading={loading} submitHandler={handleSubmit} />
    </AuthModal>
  );
};

export default LoginContainer;
