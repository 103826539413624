import { StyleFunctionProps, theme as defaultTheme } from "@chakra-ui/react";

const defaultStrippedPropsFunc = defaultTheme.components.Table.variants?.striped;

export const Table = {
  variants: {
    striped: {
      table: {
        border: "1px solid",
        borderColor: "#E9E9E9",
        bg: "brand.white",
        color: "brand.black",
      },
    },
    stripedWithTrAction: (props: StyleFunctionProps) => {
      const defaultStrippedProps = defaultStrippedPropsFunc ? defaultStrippedPropsFunc(props) : undefined;

      return {
        ...defaultStrippedProps,
        table: {
          bg: "brand.white",
          color: "brand.black",
        },
        thead: {
          tr: {
            th: {
              border: "none",
              padding: "20px 24px",
            },
          },
        },
        tbody: {
          ...defaultStrippedProps?.tbody,
          tr: {
            ...defaultStrippedProps?.tbody?.tr,
            fontSize: "14px",
            cursor: "pointer",
            bg: "brand.white",
            _hover: {
              td: {
                backgroundColor: "gray.200",
              },
            },
            td: {
              border: "none",
              "&:first-of-type": {
                borderRadius: "10px 0 0 10px",
              },
              "&:last-of-type": {
                borderRadius: "0 10px 10px 0",
              },
            },
          },
        },
      };
    },
  },
};
