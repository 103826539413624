import React, { useState, FC } from "react";
import { Formik } from "formik";
import { LoginShape } from "@containers/Auth/interface/AuthForm.interface";
import { FormProps } from "@shared/interfaces";
import { FormGenerator, Button } from "@shared/components";
import { NameOfRoutes } from "@shared/constants";
import { Box, Flex } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";

import { validationSchema, fields, initValues } from "./formHelpers";

const getRedirectLink = (email: string) => {
  const clippedEmail = email ? email.trim() : "";
  return `${NameOfRoutes.AUTH_FORGOT_PASSWORD}${clippedEmail ? `?email=${clippedEmail}` : ""}`;
};

const LoginForm: FC<FormProps<LoginShape>> = (props) => {
  const [formValues] = useState(initValues);

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        props.submitHandler && props.submitHandler(values);
        setSubmitting(false);
      }}
      initialValues={formValues}
    >
      {(formikProps) => (
        <Box mt="32px">
          <FormGenerator fields={fields} formikProps={formikProps}>
            <Link
              style={{
                paddingTop: "8px",
                display: "none",
              }}
              to={getRedirectLink(formikProps.values.email)}
            >
              Forgot Password?
            </Link>
            <Flex justifyContent="flex-end" alignItems="center" mt="24px">
              <Button
                buttonStyle="primary"
                type="submit"
                isDisabled={props.loading || !validationSchema.isValidSync(formikProps.values)}
              >
                Log In <ArrowForwardIcon ml="8px" />
              </Button>
            </Flex>
          </FormGenerator>
        </Box>
      )}
    </Formik>
  );
};

export default LoginForm;
