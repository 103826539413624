import axios, { AxiosInstance } from "axios";

import tokenHandler from "./tokenHandler";

axios.defaults.headers.post["Content-Type"] = "application/json";

const axiosInstance: AxiosInstance = axios.create();

const makeRequest =
  (instance: AxiosInstance) =>
  //eslint-disable-next-line
  (method: string, url: string, data?: any, config?: any): Promise<unknown> => {
    const token = tokenHandler.get();

    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    switch (method) {
      case "get":
        return instance.get(url, config).then((response) => response.data);
      case "delete":
        return instance.delete(url, config).then((response) => response.data);
      case "head":
        return instance.head(url, config).then((response) => response.data);
      case "options":
        return instance.options(url, config).then((response) => response.data);
      case "post":
        return instance.post(url, data, config).then((response) => response.data);
      case "put":
        return instance.put(url, data, config).then((response) => response.data);
      case "patch":
        return instance.patch(url, data, config).then((response) => response.data);
      default:
        throw new Error(`Unsupported method "${method}"`);
    }
  };

//eslint-disable-next-line
export default (method: string, url: string) => (data?: any, config?: any) =>
  makeRequest(axiosInstance)(method, url, data, config);
