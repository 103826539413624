import { Button, ModalBody, ModalFooter, ModalHeader } from "@chakra-ui/react";
import React, { FC } from "react";

interface ConfirmModalProps {
  heading?: string;
  content: string;
  onClose: () => void;
  onSuccess: () => void;
  cancelBtnText?: string;
  successBtnText?: string;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  heading,
  content,
  onClose,
  onSuccess,
  cancelBtnText,
  successBtnText,
}) => {
  return (
    <>
      {heading ? <ModalHeader>{heading}</ModalHeader> : null}
      <ModalBody padding="24px" fontSize="18px" fontWeight={600}>
        {content}
      </ModalBody>
      <ModalFooter>
        <Button variant="cancel" onClick={onClose} mr={3}>
          {cancelBtnText}
        </Button>
        <Button variant="primary" onClick={onSuccess}>
          {successBtnText}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ConfirmModal;
