import React, { lazy, FC } from "react";
import { NameOfChildRoutes, SIDEBAR_ITEMS } from "@shared/constants";
import { Box } from "@chakra-ui/react";
import { Navigate, useRoutes } from "react-router-dom";
import { Loadable, Sidebar } from "@shared/components";
import { useLogout } from "@shared/hooks";

const LeadViewContainer = Loadable(lazy(() => import("../../../Lead/containers/LeadContainer/LeadContainer")));

const routes = [
  {
    path: `${NameOfChildRoutes.LEADS.ROOT}/*`,
    element: <LeadViewContainer />,
  },
  {
    path: `/*`,
    element: <Navigate to={`${NameOfChildRoutes.LEADS.ROOT}`} />,
  },
];

const Dashboard: FC = () => {
  const logout = useLogout();

  const content = useRoutes(routes);

  return (
    <Box h="100vh" bg="brand.bg" display="flex">
      <Sidebar items={SIDEBAR_ITEMS} onLogoutClick={logout} />
      {content}
    </Box>
  );
};

export default Dashboard;
