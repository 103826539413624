import React, { lazy, FC } from "react";
import { useRoutes } from "react-router-dom";
import { Box, Divider, Flex, Image } from "@chakra-ui/react";
import { Loadable } from "@shared/components";
import { NameOfChildRoutes } from "@shared/constants";
import { GuestGuard } from "@shared/guards";
import authLogoPath from "@assets/files/images/auth/auth-logo.png";
import ProtectlineLogo from "@assets/files/images/pl-logo.png";

const LoginContainer = Loadable(lazy(() => import("../LoginContainer/LoginContainer")));
const RestoreContainer = Loadable(lazy(() => import("../RestoreContainer/RestoreContainer")));
const ChangePasswordContainer = Loadable(lazy(() => import("../ChangePasswordContainer/ChangePasswordContainer")));
const RegistrationContainer = Loadable(lazy(() => import("../RegistrationContainer/RegistrationContainer")));

const routes = [
  {
    path: `${NameOfChildRoutes.AUTH.LOGIN}`,
    default: true,
    element: (
      <GuestGuard>
        <LoginContainer />
      </GuestGuard>
    ),
  },
  {
    path: `${NameOfChildRoutes.AUTH.FORGOT_PASSWORD}`,
    element: (
      <GuestGuard>
        <RestoreContainer />
      </GuestGuard>
    ),
  },
  {
    path: `${NameOfChildRoutes.AUTH.CHANGE_PASSWORD}/:hash`,
    element: (
      <GuestGuard>
        <ChangePasswordContainer />
      </GuestGuard>
    ),
  },
  {
    path: `${NameOfChildRoutes.AUTH.REGISTRATION}`,
    element: (
      <GuestGuard>
        <RegistrationContainer />
      </GuestGuard>
    ),
  },
];

const AuthContainer: FC = () => {
  const content = useRoutes(routes);
  return (
    <Flex flexDirection="column">
      <Flex w="100%" alignItems="center" justifyContent="center" paddingY="24px">
        <Image src={ProtectlineLogo} />
      </Flex>
      <Divider m="0" />
      <Flex justifyContent="space-between" w="100%" h="calc(100vh - 113px)" margin="0 auto">
        <Flex justifyContent="center" alignItems="center" minW="50%">
          {content}
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          bg="linear-gradient(343deg, #694575 -5.23%, #8F3680 21.4%, #BC258D 36.84%, #D43772 52.98%, #E04E53 71.77%, #ED6634 89.34%)"
          minW="40%"
          paddingX="68px"
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg="#FFF"
            borderRadius="32px"
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.05)"
            p="40px"
          >
            <Image src={authLogoPath} />
            <Box textStyle="secondaryTitle" mb="16px" mt="24px" textAlign="center">
              Life insurance
            </Box>
            <Box textStyle="subtitle" textAlign="center">
              Lorem ipsum dolor sit amet consectetur. <br />
              Elementum a duis proin massa ipsum cursus.
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AuthContainer;
