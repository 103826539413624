import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

import { AppState } from "../interfaces";
import * as actions from "./actions";
import api from "./api";
import { User } from "../models";

function* getUserProfileSaga() {
  try {
    yield put(actions.startLoading());
    const { profile } = (yield call(api.loadUserProfile) as unknown) as { profile: User };

    yield put(actions.loadUserDetail.success(profile));
  } catch (error) {
    yield put(actions.loadUserDetail.failure(error as Error));
  } finally {
    yield put(actions.stopLoading());
  }
}

function* watchLoaders(payload: AnyAction) {
  const state: AppState = yield select();
  const { loadingTypes } = state.shared;

  const startedSection = loadingTypes.find(({ startActions }) => startActions.includes(payload.type));
  const stoppedSection = loadingTypes.find(({ stopActions }) => stopActions[payload.type]);

  if (startedSection) {
    yield put(actions.addLoadingSection({ loadingSection: startedSection.name, requestName: payload.type }));
  }
  if (stoppedSection) {
    yield put(
      actions.removeLoadingSection({
        loadingSection: stoppedSection.name,
        requestName: stoppedSection.stopActions[payload.type],
      }),
    );
  }
}

function* sharedSagas() {
  yield takeLatest(actions.loadUserDetail.request, getUserProfileSaga);
  yield takeEvery("*", watchLoaders);
}

export default sharedSagas;
