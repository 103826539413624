const borderRadius = {
  radii: {
    none: "0",
    base: "6px",
    login: "32px",
    table: "10px",
    full: "9999px",
  },
};

export type Radius = typeof borderRadius;

export default borderRadius;
