export const NamesOfParentRoutes = {
  AUTH: "/auth",
  DASHBOARD: "/",
};

export const NameOfRoutes = {
  SIGN_IN: "/",
  SIGN_UP: "/signup",
  AUTH: "/auth/",
  AUTH_LOGIN: "/auth/login",
  AUTH_FORGOT_PASSWORD: "/auth/restore",
  AUTH_CHANGE_PASSWORD: "/auth/change",
};

export const NameOfChildRoutes = {
  AUTH: {
    LOGIN: "/login",
    FORGOT_PASSWORD: "/restore",
    CHANGE_PASSWORD: "/change",
    REGISTRATION: "/registration",
  },
  LEADS: {
    ROOT: "/leads",
    CREATE: "/new",
    DETAILS: "/:uuid",
    EDIT: "/:id/edit",
    LIST: "/",
  },
  DEFAULT_REDIRECT: "/*",
};
