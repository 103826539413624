import React from "react";
import { MenuItem } from "@shared/interfaces";
import { ReactComponent as LeadsIcon } from "@assets/files/icons/navbar/users.svg";
import { Icon } from "@chakra-ui/react";

import { NameOfChildRoutes } from "./routes";

export const SIDEBAR_ITEMS: MenuItem[] = [
  {
    name: "Leads",
    path: `${NameOfChildRoutes.LEADS.ROOT}`,
    icon: (
      <Icon boxSize="20px" color="brand.secondary" ml="4px">
        <LeadsIcon />
      </Icon>
    ),
  },
];
