export const FONT_WEIGHT = {
  BOLD: 700,
  HEAVY: 600,
  NORMAL: 400,
};

const textStyles = {
  title: {
    fontSize: "32px",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "38px",
    color: "brand.black",
  },
  secondaryTitle: {
    fontSize: "24px",
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: "28px",
    color: "brand.black",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: FONT_WEIGHT.HEAVY,
    lineHeight: "32px",
    color: "brand.black",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "24px",
    color: "brand.subtitle",
  },
  label: {
    fontWeight: FONT_WEIGHT.NORMAL,
    fontSize: "18px",
    color: "brand.label",
    paddingLeft: "30px",
  },

  secondarySubtitle: {
    fontSize: "16px",
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: "16px",
    color: "brand.secondarySubtitle",
  },
};

export type TextStyles = typeof textStyles;

export default textStyles;
