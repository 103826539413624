export enum DashboardActionTypes {
  LOAD_LEAD_LIST = "@@DASHBOARD/LOAD_LEAD_LIST",
  LOAD_LEAD_LIST_SUCCESS = "@@DASHBOARD/LOAD_LEAD_LIST_SUCCESS",
  LOAD_LEAD_LIST_FAILURE = "@@DASHBOARD/LOAD_LEAD_LIST_FAILURE",

  SUBMIT_LEAD_MESSAGE = "@@DASHBOARD/SUBMIT_LEAD_MESSAGE",
  SUBMIT_LEAD_MESSAGE_SUCCESS = "@@DASHBOARD/SUBMIT_LEAD_MESSAGE_SUCCESS",
  SUBMIT_LEAD_MESSAGE_FAILURE = "@@DASHBOARD/SUBMIT_LEAD_MESSAGE_FAILURE",

  LOAD_LEAD = "@@DASHBOARD/LOAD_LEAD",
  LOAD_LEAD_SUCCESS = "@@DASHBOARD/LOAD_LEAD_SUCCESS",
  LOAD_LEAD_FAILURE = "@@DASHBOARD/LOAD_LEAD_FAILURE",

  SET_ACTIVE_LEAD = "@@DASHBOARD/SET_ACTIVE_LEAD",
  ADD_NEW_LEAD = "@@DASHBOARD/ADD_NEW_LEAD",

  CLEAR_LEAD_LIST = "@@DASHBOARD/CLEAR_LEAD_LIST",

  LOAD_USER_PROFILE = "@@DASHBOARD/LOAD_USER_PROFILE",
  LOAD_USER_PROFILE_SUCCESS = "@@DASHBOARD/LOAD_USER_PROFILE_SUCCESS",
  LOAD_USER_PROFILE_FAILURE = "@@DASHBOARD/LOAD_USER_PROFILE_FAILURE",

  START_CONVERSATION = "@@DASHBOARD/START_CONVERSATION",
  START_CONVERSATION_SUCCESS = "@@DASHBOARD/START_CONVERSATION_SUCCESS",
  START_CONVERSATION_FAILURE = "@@DASHBOARD/START_CONVERSATION_FAILURE",

  LOAD_AGENTS = "@@DASHBOARD/LOAD_AGENTS",
  LOAD_AGENTS_SUCCESS = "@@DASHBOARD/LOAD_AGENTS_SUCCESS",
  LOAD_AGENTS_FAILURE = "@@DASHBOARD/LOAD_AGENTS_FAILURE",

  CREATE_SCORE = "@@DASHBOARD/CREATE_SCORE",
  CREATE_SCORE_SUCCESS = "@@DASHBOARD/CREATE_SCORE_SUCCESS",
  CREATE_SCORE_FAILURE = "@@DASHBOARD/CREATE_SCORE_FAILURE",

  UPDATE_SCORE = "@@DASHBOARD/UPDATE_SCORE",
  UPDATE_SCORE_SUCCESS = "@@DASHBOARD/UPDATE_SCORE_SUCCESS",
  UPDATE_SCORE_FAILURE = "@@DASHBOARD/UPDATE_SCORE_FAILURE",
}
