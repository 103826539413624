export const Badge = {
  variants: {
    readOnlyProp: {
      bg: "gray.60",
      padding: "4px 18px",
      borderRadius: "6px",
      fontSize: "16px",
      fontWeight: 400,
      color: "brand.black",
    },
  },
};
