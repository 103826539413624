import { createAsyncAction, createAction } from "typesafe-actions";
import { DashboardActionTypes } from "@containers/Lead/store/constants";
import { Lead, GetLead, SubmitMessage, Bubble, GetLeadsRequest, LeadScoreInterface } from "@containers/Lead/interface";
import { User } from "@shared/models";

export const loadLeadList = createAsyncAction(
  DashboardActionTypes.LOAD_LEAD_LIST,
  DashboardActionTypes.LOAD_LEAD_LIST_SUCCESS,
  DashboardActionTypes.LOAD_LEAD_LIST_FAILURE,
)<GetLeadsRequest, { leads: Lead[]; total: number }, Error>();

export const loadLead = createAsyncAction(
  DashboardActionTypes.LOAD_LEAD,
  DashboardActionTypes.LOAD_LEAD_SUCCESS,
  DashboardActionTypes.LOAD_LEAD_FAILURE,
)<GetLead, Lead, Error>();

export const sendMessage = createAsyncAction(
  DashboardActionTypes.SUBMIT_LEAD_MESSAGE,
  DashboardActionTypes.SUBMIT_LEAD_MESSAGE_SUCCESS,
  DashboardActionTypes.SUBMIT_LEAD_MESSAGE_FAILURE,
)<SubmitMessage, Lead, Error>();

export const setActiveLead = createAction(DashboardActionTypes.SET_ACTIVE_LEAD)<Lead | null>();
export const addNewLead = createAction(DashboardActionTypes.ADD_NEW_LEAD)<Lead>();
export const clearLeadList = createAction(DashboardActionTypes.CLEAR_LEAD_LIST)();

export const startConversation = createAsyncAction(
  DashboardActionTypes.START_CONVERSATION,
  DashboardActionTypes.START_CONVERSATION_SUCCESS,
  DashboardActionTypes.START_CONVERSATION_FAILURE,
)<void, Bubble[], Error>();

export const loadAgents = createAsyncAction(
  DashboardActionTypes.LOAD_AGENTS,
  DashboardActionTypes.LOAD_AGENTS_SUCCESS,
  DashboardActionTypes.LOAD_AGENTS_FAILURE,
)<void, User[], Error>();

export const createScore = createAsyncAction(
  DashboardActionTypes.CREATE_SCORE,
  DashboardActionTypes.CREATE_SCORE_SUCCESS,
  DashboardActionTypes.CREATE_SCORE_FAILURE,
)<LeadScoreInterface, Lead, Error>();

export const updateScore = createAsyncAction(
  DashboardActionTypes.UPDATE_SCORE,
  DashboardActionTypes.UPDATE_SCORE_SUCCESS,
  DashboardActionTypes.UPDATE_SCORE_FAILURE,
)<LeadScoreInterface, Lead, Error>();
