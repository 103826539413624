export enum SharedActionTypes {
  NAVIGATE = "@@SHARED/NAVIGATE",

  START_LOADING = "@@SHARED/START_LOADING",
  STOP_LOADING = "@@SHARED/STOP_LOADING",
  ADD_LOADING_TYPE = "@@SHARED/ADD_LOADING_TYPE",
  REMOVE_LOADING_TYPE = "@@SHARED/REMOVE_LOADING_TYPE",

  ADD_LOADING_SECTION = "@@SHARED/ADD_LOADING_SECTION",
  REMOVE_LOADING_SECTION = "@@SHARED/REMOVE_LOADING_SECTION",
  SHOW_NOTIFICATION = "@@SHARED/SHOW_NOTIFICATION",

  GET_USER_DETAIL = "@@SHARED/GET_USER_DETAIL",
  GET_USER_DETAIL_SUCCESS = "@@SHARED/GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILURE = "@@SHARED/GET_USER_DETAIL_FAILURE",

  SHOW_OVERLAY = "@@SHARED/SHOW_OVERLAY",
  HIDE_OVERLAY = "@@SHARED/HIDE_OVERLAY",

  SHOW_MODAL = "@@SHARED/SHOW_MODAL",
  HIDE_MODAL = "@@SHARED/HIDE_MODAL",
}
