import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

export interface AuthModalProps {
  title: string;
  backClick?: () => void;
  children: React.ReactNode;
}

const AuthModal: React.FC<AuthModalProps> = (props) => {
  const { title, children, backClick } = props;

  return (
    <Flex flexDirection="column" w="572px">
      {backClick && (
        <Flex align="center" onClick={backClick}>
          <Text ml={2}>{"<-"} Back</Text>
        </Flex>
      )}
      <Text textStyle="title">{title}</Text>
      <Box w="100%">{children}</Box>
    </Flex>
  );
};

export default AuthModal;
