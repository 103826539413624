import { FC } from "react";
import { Text } from "@chakra-ui/react";

interface ErrorMessageProps {
  isTouched?: boolean;
  error?: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ isTouched, error }) => {
  return isTouched && error ? <Text color="red.500">{error}</Text> : null;
};
