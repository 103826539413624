import React from "react";
import { NavLink, useMatch } from "react-router-dom";
import { MenuItem } from "@shared/interfaces";
import { Box } from "@chakra-ui/react";

interface SidebarItemProps {
  item: MenuItem;
}

const SidebarItem = (props: SidebarItemProps) => {
  const { item } = props;
  const { path } = item;

  const isActive = useMatch({ path, end: false });

  return (
    <Box
      h="42px"
      w="42px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      border={isActive ? "2px solid" : ""}
      boxShadow={isActive ? "0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)" : ""}
      borderColor={isActive ? "brand.sidebarItemSelected" : ""}
      borderRadius="full"
    >
      <NavLink to={path}>
        <Box padding="8px 10px">{item.icon}</Box>
      </NavLink>
    </Box>
  );
};

export default SidebarItem;
