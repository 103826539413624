import { extendTheme, theme as defaultTheme } from "@chakra-ui/react";
import foundations from "@assets/theme/foundations";
import breakpoints from "@assets/theme/breakpoints";
import components from "@assets/theme/components";

export const theme = extendTheme(
  {
    ...foundations,
  },
  {
    config: { ...defaultTheme, initialColorMode: "light", useSystemColorMode: false },
    direction: defaultTheme.direction,
    transition: defaultTheme.transition,
    breakpoints,
    zIndices: defaultTheme.zIndices,
    components: { ...defaultTheme.components, ...components },
    styles: {
      ...defaultTheme.styles,
      global: {
        ...defaultTheme.styles.global,
        "html, body": {
          fontFamily: "Poppins, sans-serif",
        },
      },
    },
    colors: {},
    radii: foundations.borderRadius.radii,
    fonts: {
      heading: "Poppins, sans-serif",
      body: "Poppins, sans-serif",
    },
    fontSizes: {},
    fontWeights: {},
    lineHeights: {},
    sizes: {
      wheelPageMaxWidth: "100%",
    },
    space: {
      blockMargin: "24px",
    },
  },
);
