import config from "../../config";

export default {
  AUTH: {
    LOGIN: `${config.baseApiUrl}auth/login`,
    REGISTER: `${config.baseApiUrl}auth/register`,
    FORGOT_PASSWORD: `${config.baseApiUrl}auth/forgot_password`,
    SET_PASSWORD: `${config.baseApiUrl}auth/reset_password`,
    REGISTRATION: `${config.baseApiUrl}auth/registration`,
    ACTIVATE: `${config.baseApiUrl}auth/activate`,
  },
  USER: {
    GET_USER_INFO: `${config.baseApiUrl}user/profile`,
    GET_USER_LIST: `${config.baseApiUrl}user/list`,
  },
  LEAD: {
    ASSISTANT: `${config.baseApiUrl}chat/assistant`,
    LEAD_LIST: `${config.baseApiUrl}chat/list`,
    START_CONVERSATION: `${config.baseApiUrl}chat/start_conversation`,
    LOAD_LEAD: (id: string) => `${config.baseApiUrl}chat/${id}`,
    CREATE_SCORE: (chat_id: number) => `${config.baseApiUrl}chat/score/${chat_id}`,
    UPDATE_SCORE: (id: number) => `${config.baseApiUrl}chat/score/${id}`,
  },
};
