import React, { useEffect } from "react";
import { useRoutes, RouteObject, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, useColorMode } from "@chakra-ui/react";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { NamesOfParentRoutes } from "@shared/constants";
import { Dashboard } from "@containers/Dashboard";
import { ModalWrapper } from "@shared/components/ModalWrapper";
import { useNotification } from "@shared/hooks";

import { AuthContainer } from "../Auth";
import { NotFound } from "../../shared/components";
import { AuthGuard, GuestGuard } from "../../shared/guards";

const routes: RouteObject[] = [
  {
    path: `${NamesOfParentRoutes.DASHBOARD}*`,
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
  },
  {
    path: `${NamesOfParentRoutes.AUTH}/*`,
    element: (
      <GuestGuard>
        <AuthContainer />
      </GuestGuard>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const App = () => {
  const dispatch = useDispatch();
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  const notification = useSelector(sharedSelectors.getNotification());
  const navigateURL = useSelector(sharedSelectors.getNavigateURL());

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL);
      dispatch(sharedActions.navigate(""));
    }
  }, [navigateURL, dispatch, navigate]);

  useEffect(() => {
    if (colorMode === "light") return;
    toggleColorMode();
  }, [colorMode, toggleColorMode]);

  const content = useRoutes(routes);
  useNotification(notification);

  return (
    <Box className="App">
      <ModalWrapper />
      {content}
    </Box>
  );
};

export default App;
