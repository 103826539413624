import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { GetLead, GetLeadsRequest, SubmitMessage, LeadScoreInterface } from "@containers/Lead/interface";

export default {
  loadLead: ({ uuid }: GetLead) => request(METHODS.GET, API.LEAD.LOAD_LEAD(uuid))(),
  loadLeadList: (payload: GetLeadsRequest) =>
    request(METHODS.GET, API.LEAD.LEAD_LIST)(null, {
      params: payload,
    }),
  sendMessage: (payload: SubmitMessage) => request(METHODS.POST, API.LEAD.ASSISTANT)(payload),
  startConversation: () => request(METHODS.GET, API.LEAD.START_CONVERSATION)(),
  loadAgents: () => request(METHODS.GET, API.USER.GET_USER_LIST)(),
  createScore: (payload: LeadScoreInterface) => request(METHODS.POST, API.LEAD.CREATE_SCORE(payload.chat_id))(payload),
  updateScore: (payload: LeadScoreInterface) => request(METHODS.PUT, API.LEAD.UPDATE_SCORE(payload.id ?? 0))(payload),
};
