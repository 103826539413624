import React from "react";
import { MenuItem } from "@shared/interfaces";
import { Box, Button, Icon, Image } from "@chakra-ui/react";
import { User } from "@shared/models";
import NavbarLogo from "@assets/files/icons/navbar/navbar-logo.png";
import { ReactComponent as LogoutIcon } from "@assets/files/icons/general/logout.svg";

import { SidebarItems } from "../SidebarItems";

export interface MainSidebarProps {
  user?: User | null;
  items: MenuItem[];
  onLogoutClick: () => void;
}

const Sidebar = (props: MainSidebarProps) => {
  const { items, onLogoutClick } = props;

  return (
    <Box h="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center" padding="16px">
        <Image src={NavbarLogo} ml="4px" />
      </Box>
      <Box>
        <SidebarItems items={items} />
      </Box>
      <Box margin="auto 0 0">
        <Box padding="16px 10px 20px" width="100%" display="flex" alignItems="center" justifyContent="center">
          <Button onClick={onLogoutClick} variant="ghost" _hover={{ bg: "transparent" }}>
            <Icon boxSize="26px" color="brand.secondary" ml="8px">
              <LogoutIcon />
            </Icon>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
