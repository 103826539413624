import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { Lead, LeadStateType } from "../interface";
import * as actions from "./actions";
import { DashboardActionTypes } from "./constants";

type Action = ActionType<typeof actions>;

export const initialState: LeadStateType = {
  activeLead: null,
  leadList: null,
  initialBubbles: [],
  agents: [],
  total: 0,
};

const updateLeadList = (
  state: LeadStateType,
  action: {
    type?:
      | DashboardActionTypes.SUBMIT_LEAD_MESSAGE_SUCCESS
      | DashboardActionTypes.LOAD_LEAD_SUCCESS
      | DashboardActionTypes.CREATE_SCORE_SUCCESS
      | DashboardActionTypes.UPDATE_SCORE_SUCCESS;
    payload: Lead;
  },
) => {
  return produce(state, (nextState) => {
    nextState.activeLead = action.payload;

    if (state.leadList) {
      nextState.leadList = state.leadList.map((lead) => (lead.uuid === action.payload.uuid ? action.payload : lead));
    }
  });
};

const reducer = createReducer<LeadStateType, Action>(initialState)
  .handleAction(actions.createScore.success, (state, action) => updateLeadList(state, action))
  .handleAction(actions.updateScore.success, (state, action) => updateLeadList(state, action))
  .handleAction(actions.sendMessage.success, (state, action) => updateLeadList(state, action))
  .handleAction(actions.loadLead.success, (state, action) => updateLeadList(state, action))
  .handleAction(actions.loadLeadList.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.leadList = action.payload.leads;
      nextState.total = action.payload.total;
    }),
  )
  .handleAction(actions.setActiveLead, (state, action) =>
    produce(state, (nextState) => {
      nextState.activeLead = action.payload;
    }),
  )
  .handleAction(actions.addNewLead, (state, action) =>
    produce(state, (nextState) => {
      if (state.leadList) {
        nextState.leadList = [action.payload, ...state.leadList];
      }
    }),
  )
  .handleAction(actions.clearLeadList, (state) =>
    produce(state, (nextState) => {
      nextState.leadList = null;
    }),
  )

  .handleAction(actions.startConversation.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.initialBubbles = action.payload;
    }),
  )
  .handleAction(actions.loadAgents.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.agents = action.payload;
    }),
  );

export { reducer as LeadReducer };
