import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logout as logoutAction } from "@containers/Auth/store/actions";
import { actions } from "@shared/store";
import { MODAL_TYPES } from "@shared/constants";
import { clearLeadList } from "@containers/Lead/store/actions";

function useLogout() {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    dispatch(
      actions.showModal({
        type: MODAL_TYPES.CONFIRM,
        props: {
          content: "Are you sure you want to log out?",
          cancelBtnText: "Cancel",
          successBtnText: "Log Out",
          onSuccess: () => {
            dispatch(clearLeadList());
            dispatch(logoutAction.request());
          },
        },
      }),
    );
  }, [dispatch]);

  return logout;
}

export default useLogout;
