import React, { useMemo } from "react";
import { StylesConfig } from "react-select";
import classnames from "classnames";
import { FieldItemType, Option } from "@shared/interfaces";
import { ErrorMessage, Select } from "@shared/components";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface SelectFieldItem extends RenderField {
  type: FieldItemType.SELECT | FieldItemType.MULTI_SELECT;
  label?: string;
  options: Option[];
  disabled?: boolean;
  placeholder?: string;
  styles?: StylesConfig;
  helpText?: string;
  isSearchable?: boolean;
  tooltip?: string;
  tooltipLabel?: string;
  relatedFields?: Record<string, number | string[]>;
  isMulti?: boolean;
  isClearable?: boolean;
}

export const GenerateSelect: GenerateRenderField<SelectFieldItem> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    wrapperClass,
    disabled,
    label,
    name,
    options,
    placeholder,
    isSearchable = true,
    isClearable,
  } = props;

  const handleChange = (option: Option) => {
    setFieldValue(name, option);
  };

  const handleInputChange = () => {
    setFieldTouched(name, true, false);
  };

  const currentOptionValue = useMemo(() => {
    return options.find((option) => option.value === values[name]) || null;
  }, [options, name, values]);

  return (
    <div
      className={classnames("render-select", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}
    >
      {label && <label className={classnames({ disabled })}>{label}</label>}
      <div className={classnames("select", { disabled })}>
        <Select
          isDisabled={disabled || !options.length}
          components={{
            IndicatorSeparator: () => null,
          }}
          isSearchable={isSearchable}
          onChange={(option) => handleChange(option as Option)}
          onInputChange={handleInputChange}
          value={currentOptionValue}
          options={options}
          name={name}
          placeholder={placeholder || "Start typing here"}
          noOptionsMessage={() => "No matches found"}
          isClearable={isClearable}
        />
      </div>
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
