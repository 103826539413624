export const Button = {
  baseStyle: {
    height: "48px",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "32px",
  },
  variants: {
    primary: {
      backgroundColor: "brand.primary",
      color: "brand.white",
      borderRadius: "100px",
      _disabled: {
        backgroundColor: "brand.primary",
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "brand.primary",
        _disabled: {
          backgroundColor: "brand.primary",
          opacity: 0.6,
        },
      },
    },
    cancel: {
      backgroundColor: "brand.cancel",
      color: "brand.primary",
      borderRadius: "100px",
      border: "2px solid #58A1EA",
    },

    ghost: {
      backgroundColor: "transparent",
      color: "brand.primary",
      padding: "0",
      _disabled: {
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "transparent",
      },
      _active: {
        backgroundColor: "transparent",
      },
    },
  },
};
