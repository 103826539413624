const colors = {
  brand: {
    bg: "#FFF",
    primary: "#58A1EA",
    black: "#000000",
    subtitle: "#303030",
    inputBg: "#F2EDF4",
    label: "#A99AB3",
    placeholder: "#A99AB3",
    sidebarBackground: "#FFF",
    sidebarItemSelected: "#664674",
    containerBg: "#F8F8F9",
    tableHeaderTextColor: "#5E5E5E",
    tableTextColor: "#303030",
    scrollBar: "#5E5E5E",
    borderColor: "#E9E9E9",
    botMessageColor: "#E9E9E9",

    secondary: "#A71C27",
    white: "#FFFFFF",
    cancel: "#FFF",
    checkMark: "#46BA90",
    secondarySubtitle: "#7E7E7E",
    inputFocusBorder: "#7E7E7E",
  },
  gray: {
    40: "#F1F1F1",
    50: "#E7E7E7",
    60: "#EDEDED",
    100: "#F1F1F1",
  },
} as const;

export type Colors = typeof colors;
export default colors;
